export default function ({}) {
  if (import.meta.client) {
    // const token = localStorage.getItem("spv-token");
    // const loginState = localStorage.getItem("spv-login-state");
    const token = sessionStorage.getItem("spv-token");
    const loginState = sessionStorage.getItem("spv-login-state");

    if (token && loginState) {
      return navigateTo("/dashboard");
    } else {
      return;
    }
  }
}
